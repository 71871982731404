module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","name":"Hittites Technology India Limited","description":"Hittites Technology India Limited provides high quality products and services to the customers. What differentiates us from other businesses is our ability to truly connect with our customers, and provide them exceptional and compassionate service they deserve.","short_name":"Hittites","start_url":"/","background_color":"#222222","theme_color":"#d84e18","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"eb38c567131d696790d2886e90cc9145"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
